@font-face {
  font-family: Non-SeasonalGeo-Medium;
  src: url(./Non-SeasonalGeo-Medium.otf) format('opentype');
}

$header-one-level: mat.define-typography-level(
  $font-family: 'Non-SeasonalGeo-Medium, Arial',
  $font-size: 100px,
  $line-height: 1,
  $letter-spacing: 0px,
);

$header-two-level: mat.define-typography-level(
  $font-family: 'Non-SeasonalGeo-Medium, Arial',
  $font-size: 50px,
  $line-height: 1,
  $letter-spacing: 0px,
);

$header-three-level: mat.define-typography-level(
  $font-family: 'Non-SeasonalGeo-Medium, Arial',
  $font-size: 30px,
  $line-height: 1,
  $letter-spacing: 0px,
);

$header-four-level: mat.define-typography-level(
  $font-family: 'Non-SeasonalGeo-Medium, Arial',
  $font-size: 20px,
  $line-height: 1,
  $letter-spacing: 0px,
);

$header-five-level: mat.define-typography-level(
  $font-family: 'Non-SeasonalGeo-Medium, Arial',
  $font-size: 10px,
  $line-height: 1,
  $letter-spacing: 0px,
);

$body-level: mat.define-typography-level(
  $font-family: 'Non-SeasonalGeo-Medium, Arial',
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: 0px,
);

$body-bold-level: mat.define-typography-level(
  $font-family: 'Non-SeasonalGeo-Medium, Arial',
  $font-weight: bold,
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: 0px,
);
