html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Non-SeasonalGeo-Medium, Arial !important;
}
div,
span,
table {
  font-family: Non-SeasonalGeo-Medium, Arial !important;
}

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-pulse {
  margin-top: 20px;
  content: url('/assets/images/aritzia_logo.svg');
  animation: pulse 0.75s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate
    both;
}

@keyframes pulse {
  to {
    transform: scale(1.1);
  }
}
